var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"designing-table-tools"},[_vm._l((_vm.toolsList),function(item,index){return [_c('button',{directives:[{name:"show",rawName:"v-show",value:(item.isVisible ?? true),expression:"item.isVisible ?? true"},{name:"permission",rawName:"v-permission",value:(item.permission),expression:"item.permission"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideClick),expression:"outsideClick"},{name:"tooltip",rawName:"v-tooltip",value:(item.isDisabled ? {} : item.tooltip),expression:"item.isDisabled ? {} : item.tooltip"}],key:index,staticClass:"designing-table-tools__tool",class:{
                'designing-table-tools__tool--disabled': item.isDisabled,
                'designing-table-tools__tool--active': item.isActive,
                'designing-table-tools__tool--without-margin': item.withoutMargin,
                'designing-table-tools__tool--without-fill': item.withoutFill,
                'designing-table-tools__tool--green': item.isGreen,
            },style:([item.padding ? { padding: item.padding.top + 'px ' + item.padding.right + 'px' } : _vm.styles]),attrs:{"type":"button","disabled":item.isDisabled},on:{"click":function($event){return _vm.callbackFunction($event, item)}}},[(item.counter)?_c('span',{staticClass:"designing-table-tools__tool-counter"},[_vm._v(_vm._s(item.counter))]):_vm._e(),(item.icon)?_c(item.icon,{tag:"component"}):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }